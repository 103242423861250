<template>

  <div id="app">
    <div id="main">
      <div class="wrap">

        <div class="left">
          <template v-if="!pc">
              <div class="prompt">
                $<span>&#x258c;</span>
              </div>
          </template>
          <h1>
            <span>G</span>
            <span>R</span>
            <span>E</span>
            <span>ー</span>
            <br>
            <span>S</span>
            <span>A</span>
            <span>N</span>
            <span>ー</span>
            <br>
            <span>N</span>
            <span>O</span>
            <span>ー</span>
            <br>
            <span>I</span>
            <span>E</span>
          </h1>
        </div>

        <div class="right">
          <template v-if="pc">
              <div class="prompt">
                $<span>&#x258c;</span>
              </div>
          </template>

          <div class="prof">
            <strong>Yasuhiro Yamada</strong><br>
            Shellgei lover, Cure Engineer, Unkontributors, Egison maintener<br>
            <img src="img/ic_email.svg" alt="email"> yamada[at]gr3.ie
          </div>

        </div>

        <template v-if="pc">
          <div class="arrow">
            <div class="arrowWrap">
              <span></span>
              <span></span>
            </div>
          </div>
        </template>

        <Ojichat :containStyle="containStyle" :pc="pc"></Ojichat>

      </div>
    </div>

    <div id="work">
      <div class="wrap">
        <h2 class="workTitle">SNS</h2>

        <ul class="sns">
          <li><a href="https://github.com/greymd" target="_blank"><img src="img/ic_github.svg" alt="GitHub"></a></li>
          <!-- <li><a href="https://www.facebook.com/yasuhiro.yamada.0104/" target="_blank"><img src="img/ic_facebook.svg" alt="Facebook"></a></li>-->
          <li><a href="https://twitter.com/grethlen" target="_blank"><img src="img/ic_twitter.svg" alt="Twitter"></a></li>
        </ul>

        <h2 class="workTitle">Community</h2>
        <ul class="communityList">
          <li><a href="https://www.facebook.com/groups/141271259271220" target="_blank">Shellgei</a></li>
          <li><a href="https://github.com/unkontributors" target="_blank">Unkontributors</a></li>
          <li><a href="https://github.com/orgs/egison/people" target="_blank">Egison</a></li>
        </ul>
        <h2 class="workTitle">Softwares</h2>
        <ul class="softwaresList">
          <li v-for="oss in osses" v-bind:key="oss.title">
            <a v-bind:href="oss.url" target="_blank">
              <div class="img"><img :src="oss.icon" /></div>
              <div class="title">{{oss.title}}</div>
              <div class="lang">{{oss.language}}</div>
              <div class="desc">{{oss.description}}</div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div id="article">
      <ul class="articleTag">
        <li v-for="attr in searchAttr" v-bind:key="attr" v-on:click="excludeOther(attr, toggle)">

            <input type="checkbox" v-model="toggle[attr]" :class="{ 'articleTagChecked': toggle[attr] }">
            <label><span> {{attr}}</span></label>
        </li>
      </ul>

      <div class="articleYear" v-for="year in filteredArticles.years" v-bind:key="year">
        <h2 class="articleTitle">{{ year }}</h2>
        <ul class="articleBox">
          <li v-for="article in filteredArticles.articles[year]" v-bind:key="article.title">
            <a :href="article.url" target="_blank">
              <div class="title">{{ article.title }}</div>
              <div class="tag"><span>{{ article.language }}</span><span>{{ article.type }}</span><span>{{ article.service }}</span><span>{{ article.published_at }}</span></div>
            </a>
          </li>
        </ul>
      </div>

    </div>


    <footer>Copyright ©2021. All rights reserved.</footer>
  </div>
</template>

<script>
import articles from '../../components/json/articles.json'
import osses from '../../components/json/oss.json'
import Ojichat from '../../components/Ojichat.vue'

articles.sort(function(x, y) {
  let a = x.published_at
  let b = y.published_at
  return a == b ? 0 : a < b ? 1 : - 1
})

let articleType = {}
// let articleLang = {}
// let articleServ = {}
for (let i = 0, len = articles.length; i < len; i++) {
  articleType[articles[i].type] = 1
  // articleLang[articles[i].language] = 1
  // articleServ[articles[i].service] = 1
}

let articleAttr = Array()
articleAttr = articleAttr.concat(Object.keys(articleType))
// articleAttr = articleAttr.concat(Object.keys(articleLang))
// articleAttr = articleAttr.concat(Object.keys(articleServ))

let toggle = {}
for (let i = 0, len = articleAttr.length; i < len; i++) {
  toggle[articleAttr[i]] = false
}

export default {
  components: {
    Ojichat
  },
  data () {
    return {
      keyword: "",
      toggle: toggle,
      osses: osses,
      searchAttr: articleAttr,
      width: window.innerWidth,
      height: window.innerHeight,
      pc: true,
      containStyle:{
        width: '100%',
        height: '100%',
      },
    }
  },
  methods: {
    excludeOther: function (attr, toggle) {
      for (let keyword in toggle) {
        if (keyword === attr) {
          toggle[keyword] = !toggle[keyword]
        } else {
          toggle[keyword] = false
        }
      }
    },
    handleResize: function() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      let width = window.innerWidth;
      let height = window.innerHeight;
      this.containStyle.width =  width + 'px';
      this.containStyle.height =  height + 'px';

      if (this.width <= 769) {
        this.pc = false;
      } else {
        this.pc = true;
      }
    },
  },
  computed: {
        filteredArticles: function () {
        // 年ごとにまとめる
        let years = Array()
        let sortedArticles = {}
        let toggledKeywords = Array()
        let isArticleRemaining = Array(articles.length)

        for (let i = 0, len = articles.length; i < len; i++) {
          isArticleRemaining[i] = true;
        }

        // キーワードを抽出
        for (let keyword in toggle) {
          if (toggle[keyword]) {
            toggledKeywords.push(keyword)
          }
        }

        if (toggledKeywords.length > 0) {
          for (let keyword in toggledKeywords) {
            let k = toggledKeywords[keyword]
            for (let i = 0, len = articles.length; i < len; i++) {
              // すでに他のキーワードを含んでいない判定がされていたらスキップ
              if( !isArticleRemaining[i] ) {
                continue
              }
              // その記事がキーワードを含んでいた場合
              // if (articles[i].type.indexOf(k) !== -1 || articles[i].language.indexOf(k) !== -1 || articles[i].service.indexOf(k) !== -1) {
              if (articles[i].type.indexOf(k) !== -1) {
                isArticleRemaining[i] = true
              } else {
                isArticleRemaining[i] = false
              }
            }
          }
        }
        for (let i = 0, len = articles.length; i < len; i++) {
          if( !isArticleRemaining[i] ) {
            continue
          }
          let date_parts = articles[i].published_at.split('-');
          let year = date_parts[0]
          if (sortedArticles[year] === undefined) {
            years.push(year)
            sortedArticles[year] = Array()
          }
          sortedArticles[year].push(articles[i])
        }
        return {
          years: years,
          articles: sortedArticles
        }
      }
  },
  mounted: function () {
    // タッチパネル非対応の場合には no-touch のクラスを追加する
    if (!("ontouchstart" in document.documentElement)) {
      document.documentElement.className += " noTouch";
    }
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  destroyed () {
    if (process.client) {
      window.removeEventListener('resize', this.handleResize)
    }
  },
}

</script>

<style>


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Oswald:wght@500&family=Source+Code+Pro:wght@500&family=Work+Sans:wght@400;500;600;700;800&display=swap');
@import '../../assets/styles.module.css';

</style>
